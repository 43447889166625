export const CASE_STUDIES = {
  en: 'Case studies',
  fr: 'Campagnes'
}

export const CONTACT = {
  en: 'Contact',
  fr: 'Contactez nous'
}

export const LOGIN = {
  en: 'Login',
  fr: 'Connectez vous'
}

export const SIGNUP_TO_NEWSLETTER = {
  en: 'Subscribe to our newsletter',
  fr: 'Inscrivez vous à notre newsletter'
}

export const SUBSCRIBE = {
  en: 'Subscribe',
  fr: 'Abonnez vous'
}

export const SUBSCRIBED = {
  en: 'Subscribed',
  fr: 'Abonné'
}

export const DROP_US_LINE = {
  en: 'Drop us a line',
  fr: 'N\'hésitez pas à nous contacter'
}

export const PING_US_MESSAGE = {
  en: 'Ping us a message',
  fr: 'Envoie-nous un message'
}

export const FIND_US = {
  en: 'Find us',
  fr: 'Où nous trouvez'
}

export const KEEP_UP_TO_DATE = {
  en: 'Keep up to date',
  fr: 'Abonnez vous'
}

export const LEARN_MORE = {
  en: 'Learn more',
  fr: 'En savoir plus'
}

export const DISCOVER = {
  en: 'Discover',
  fr: 'En savoir plus'
}

export const PRIVACY_POLICY = {
  en: 'Privacy policy',
  fr: 'Politique de confidentialité'
}

export const TERMS = {
  en: 'Terms',
  fr: 'Conditions générales'
}

export const BACK_TO_CASESTUDIES = {
  en: 'Back to case studies',
  fr: 'Retour'
}

export const NEXT_CASESTUDY = {
  en: 'Next case study',
  fr: 'Crochaines campagnes'
}

export const NAME = {
  en: 'Name',
  fr: 'Nom'
}

export const PHONE = {
  en: 'Phone',
  fr: 'Téléphone'
}

export const SUBJECT = {
  en: 'Subject',
  fr: 'Sujet'
}

export const MESSAGE = {
  en: 'Message',
  fr: 'Message'
}

export const SEND = {
  en: 'Send',
  fr: 'Envoyer'
}

export const SENT = {
  en: 'Sent',
  fr: 'Expédié'
}
