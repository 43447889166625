import React, {useState} from 'react'
import classnames from 'classnames'
import {StaticQuery, graphql} from 'gatsby'
import {LocaleLink, localiseObject, localiseMessage} from '../helpers/locale'
import {unblockPortableText} from '../helpers/sanity'
import {CASE_STUDIES, CONTACT, LOGIN, LEARN_MORE} from '../helpers/messages'

export default function Header ({fix, onLoginClick, onContactClick}) {

  const [menuExpanded, setMenuExpanded] = useState(false)
  const [currentMenuId, setCurrentMenuId] = useState(false)

  const showMenu = (menuId, {toggle} = {}) => {

    setMenuExpanded(prevState => toggle ? !prevState : true)
    setCurrentMenuId(menuId)

  }

  const collapseMenu = () => setMenuExpanded(false)

  const className = classnames({
    'header': true,
    'header--fixed': fix,
    'header--fill': menuExpanded || fix,
    'header--show-menu': menuExpanded
  })

  const hamburgerClassName = classnames({
    'hamburger': true,
    'hamburger--collapse': true,
    'is-active': menuExpanded
  })

  return (
    <>
      <header className={className} onMouseLeave={collapseMenu}>
        <div className="header__inner">
          <div className="header__nav">
            <div className="header__nav-item" onMouseOver={() => showMenu('services')}>Services</div>
            <LocaleLink to="/casestudies">
              <div className="header__nav-item">{localiseMessage(CASE_STUDIES)}</div>
            </LocaleLink>
          </div>
          <LocaleLink className="header__logo" to="/"></LocaleLink>
          <nav className="header__nav header__nav--right">
            <div className="header__nav-item header__nav-item--contact" onClick={onContactClick}>{localiseMessage(CONTACT)}</div>
            <div className="header__nav-item header__nav-item--login" onClick={onLoginClick}>{localiseMessage(LOGIN)}</div>
            <div className="header__nav-item header__nav-item--menu-toggle" onClick={() => showMenu('nav', {toggle: true})}>
              <button className={hamburgerClassName}>
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
            </div>
          </nav>
        </div>
        <div className="header__menu">
          <div
            className={classnames({
              'header__menu-items': true,
              'header__menu-items--show': currentMenuId == 'nav'
            })}
          >
            <div className="header__menu-item">
              <button className="header__menu-item-title" onClick={() => showMenu('services')}>Services</button>
            </div>
            <div className="header__menu-item">
              <LocaleLink className="header__menu-item-title" to="/casestudies">{localiseMessage(CASE_STUDIES)}</LocaleLink>
            </div>
            <div className="header__menu-item">
              <button className="header__menu-item-title" onClick={onContactClick}>{localiseMessage(CONTACT)}</button>
            </div>
          </div>
          <nav
            className={classnames({
              'header__menu-items': true,
              'header__menu-items--show': currentMenuId == 'services'
            })}
            role="navigation"
            aria-label="Main"
          >
            <StaticQuery
              query={graphql`
                query HeaderServices {
                  sanityHomePage {
                    services {
                      links {
                        _key
                        _type
                        reference {
                          ... on SanityRetailPage {
                            id
                            title {
                              en
                              fr
                            }
                            slug {
                              current
                            }
                            _rawHero
                          }
                          ... on SanityExperientialPage {
                            id
                            title {
                              en
                              fr
                            }
                            slug {
                              current
                            }
                            _rawHero
                          }
                          ... on SanityNetworkPage {
                            id
                            title {
                              en
                              fr
                            }
                            slug {
                              current
                            }
                            _rawHero
                          }
                        }
                      }
                    }
                  }
                }
              `}
              render={({sanityHomePage: {services}}) => {
                const {links} = services
                return links.map((service, i) => {

                  const {reference: {_rawHero, title, slug: {current}}} = service

                  return (
                    <div key={i} className="header__menu-item">
                      <h2 className="header__menu-item-title">{localiseObject(title)}</h2>
                      {unblockPortableText(localiseObject(_rawHero.body))}
                      <LocaleLink to={`/${current}`} className="button">{localiseMessage(LEARN_MORE)}</LocaleLink>
                    </div>
                  )
                })
              }}
            />
          </nav>
        </div>
      </header>
    </>
  )
}
