import React, {useState, useEffect, useCallback} from 'react'
import {StaticQuery, graphql} from 'gatsby'
import {localiseObject} from '../helpers/locale'
import {unblockPortableText} from '../helpers/sanity'
import LocalStorage from '../helpers/local-storage'
import classnames from 'classnames'

export default function CookieModal () {

  const [acceptsCookies, setAcceptsCookies] = useState(true)
  let localStorage

  const closeClicked = useCallback(() => {

    localStorage.set('accepts-cookies', true)
    setAcceptsCookies(true)

  }, [localStorage])

  useEffect(() => {

    localStorage = LocalStorage()

    const timeout = setTimeout(() => {

      setAcceptsCookies(localStorage.get('accepts-cookies', false), [])

    }, 2000)

    // this function will run on unmounting of component
    return () => clearTimeout(timeout)

  }, [])

  const className = classnames({
    'cookie-modal': true,
    'cookie-modal--show': !acceptsCookies
  })

  return (
    <StaticQuery
      query={graphql`
        query CookieModalBodyQuery {
          allSanityMiscSiteContentPage {
            nodes {
              _rawCookiePolicyMessage
            }
          }
        }
      `}
      render={({allSanityMiscSiteContentPage: {nodes: {0: {_rawCookiePolicyMessage}}}}) => {

        return (
          <aside className={className}>
            <div className="cookie-modal__close" onClick={closeClicked}></div>
            {unblockPortableText(localiseObject(_rawCookiePolicyMessage))}
          </aside>
        )
      }}
    />
  )
}
