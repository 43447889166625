import React, {useState} from 'react'
import classnames from 'classnames'
import {useLocale, getLanguageFromLocale, getPrefferedLocaleFromBrowser, setLocale} from '../helpers/locale'
import {LOCALES} from '../helpers/constants'

export default function LocaleModal ({show, onCloseClick}) {

  const locale = useLocale()
  const [prefferedLocale, setPrefferedLocale] = useState(getPrefferedLocaleFromBrowser())

  const onPrefferedLocaleChange = e => setPrefferedLocale(e.target.value)

  const onButtonPressed = () => {

    setLocale({fromLocale: locale, toLocale: prefferedLocale})

    onCloseClick()

  }

  const onCloseButtonPressed = () => {

    setLocale({fromLocale: locale, toLocale: locale})

    onCloseClick()

  }

  const className = classnames({
    'locale-modal': true,
    'locale-modal--show': show
  })

  return (
    <aside className={className}>
      <div className="locale-modal__lightbox" onClick={onCloseButtonPressed}></div>
      <div className="locale-modal__box">
        <h3>Are we speaking your language?</h3>
        <p>
          Choose another country or region to see content specific to your location.
        </p>
        {prefferedLocale && (
          <div className="locale-modal__locale">
            <select  className={`select-locale select-locale--${prefferedLocale}`} name="locale" defaultValue={prefferedLocale} onChange={onPrefferedLocaleChange}>
              {LOCALES.map((locale, i) => (
                <option key={i} value={locale}>{getLanguageFromLocale(locale)}</option>
              ))}
            </select>
            <button className="button button--filled" onClick={onButtonPressed}>Go</button>
          </div>
        )}
        <div className="locale-modal__close" onClick={onCloseButtonPressed}></div>
      </div>
    </aside>
  )
}
