import {useState, useEffect, useRef} from 'react'
import {makeArray, realModulo, isBrowserEnvironment} from '../helpers/misc'

export const useIsScrolled = () => {

  const startingScrollY = isBrowserEnvironment() ? window.scrollY : 0
  const scrolledThresholdY = 30
  const [isScrolled, setIsScrolled] = useState(startingScrollY > scrolledThresholdY)

  useEffect(() => {

    const scrolled = () => {

      const isNowScrolled = window.scrollY > scrolledThresholdY

      setIsScrolled(isNowScrolled)

    }

    window.addEventListener('scroll', scrolled)

    return () => window.removeEventListener('scroll', scrolled)

  }, [])

  return isScrolled

}

export const useCarrousel = (numItems, {prevIsLastIndex, interval = 8000}) => {

  const getClassModifiers = (index, prevIndex, nextIndex) => {

    return makeArray(numItems).map((item, i) => {

      if (i === index)
        return '--current'
      else if (i === prevIndex)
        return '--prev'
      else if (i == nextIndex)
        return '--next'
      else
        return ''

    })
  }

  const timeoutRef = useRef(null)
  const lastIndex = numItems - 1
  const [state, setState] = useState({
    index: 0,
    prevIndex: lastIndex,
    isReady: false,
    isPaused: false,
    isSingleItem: numItems < 2,
    classModifiers: getClassModifiers(0, lastIndex)
  })

  const nextAutoSlidingTimeout = (stopAutoSliding) => {

    clearTimeout(timeoutRef.current)

    if (!stopAutoSliding && interval && !state.isSingleItem)
      timeoutRef.current = setTimeout(nextItem, interval)

  }

  const adjustIndex = (by, stopAutoSliding) => {

    setState(prevState => {

      const index = realModulo(prevState.index + by, numItems)
      const prevIndex = prevIsLastIndex ? prevState.index : realModulo(index - 1, numItems)
      const nextIndex = realModulo(index + 1, numItems)

      return {
        index,
        prevIndex,
        nextIndex,
        isReady: true,
        isPaused: !!stopAutoSliding,
        isSingleItem: prevState.isSingleItem,
        classModifiers: getClassModifiers(index, prevIndex, nextIndex),
        direction: by > 0 ? 'forwards' : 'backwards'
      }
    })

    nextAutoSlidingTimeout(stopAutoSliding)

  }

  const goToIndex = (index, stopAutoSliding) => adjustIndex(index - state.index, stopAutoSliding)
  const nextItem = stopAutoSliding => adjustIndex(1, stopAutoSliding)
  const prevItem = stopAutoSliding => adjustIndex(-1, stopAutoSliding)
  const pauseAutoSliding = () => adjustIndex(0, true)
  const resumeAutoSliding = () => adjustIndex(0, false)

  useEffect(() => {

    nextAutoSlidingTimeout()

    return () => nextAutoSlidingTimeout(true)

  }, [])

  return [state, {adjustIndex, goToIndex, nextItem, prevItem, pauseAutoSliding, resumeAutoSliding}]

}
