import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import SanityImageUrl from '@sanity/image-url'
import {LocaleLink} from '../helpers/locale'

const builder = SanityImageUrl({projectId: 'czjf14tv', dataset: 'production'})

export function urlFor (source) {

  return builder.image(source).url() + '?&dl'

}

export function getThumbnailUrlFromVideo (asset) {

  return `https://image.mux.com/${asset.playbackId}/thumbnail.jpg`

}

const serializers = {
  marks: {
    internalLink: ({mark, children}) => {
      const {slug = {}} = mark
      const href = `/${slug.current}`
      return <LocaleLink to={href}>{children}</LocaleLink>
    },
    link: ({mark, children}) => {
      const { href } = mark
      return <a href={href} rel="noopener noreferrer" target="_blank">{children}</a>
    }
  }
}

export function unblockPortableText (blocks = []) {

  if (blocks)
    return <BlockContent blocks={blocks} serializers={serializers} />
  else
    return null

}

export function unblockPortableTextToPlainText (blocks = [], {newLines} = {}) {

  return blocks.map(block => {

    if (block._type !== 'block' || !block.children) return ''

    return block.children.map(child => child.text).join('')

  }).join(newLines ? '\n\n' : ' ')
}
