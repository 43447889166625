import React from 'react'
import classnames from 'classnames'
import {VENDOS_URLS} from '../helpers/constants'
import {DISCOVER, LOGIN} from '../helpers/messages'
import {localiseObject} from '../helpers/locale'
import {unblockPortableText} from '../helpers/sanity'
import {StaticQuery, graphql} from 'gatsby'

export default function VendosModal ({show, onCloseClick}) {

  const className = classnames({
    'vendos-modal': true,
    'vendos-modal--show': show
  })

  return (
    <StaticQuery
      query={graphql`
        query VendosModalBodyQuery {
          allSanityMiscSiteContentPage {
            nodes {
              _rawVendosModalBody
            }
          }
        }
      `}
      render={({allSanityMiscSiteContentPage: {nodes: {0: {_rawVendosModalBody}}}}) => {

        return (
          <aside className={className}>
            <div className={"vendos-modal__lightbox"} onClick={onCloseClick}></div>
            <div className="vendos-modal__box">
              <div className="vendos-modal__left">
                <div className="vendos-modal__logo"></div>
                {unblockPortableText(localiseObject(_rawVendosModalBody))}
              </div>
              <div className="vendos-modal__right">
                <div className="vendos-modal__close" onClick={onCloseClick}></div>
                <div className="vendos-modal__buttons">
                  <a className="button button--vendos button--filled" href={VENDOS_URLS.LANDING} rel="noopener noreferrer" target="_blank">{localiseObject(DISCOVER)}</a>
                  <a className="button button--vendos" href={VENDOS_URLS.LOGIN} rel="noopener noreferrer" target="_blank">{localiseObject(LOGIN)}</a>
                </div>
              </div>
            </div>
          </aside>
        )
      }}
    />
  )
}
