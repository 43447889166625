import React from 'react'
import {CONTACT_EMAIL, CONTACT_NUMBER} from '../helpers/constants'
import {localiseMessage} from '../helpers/locale'
import {DROP_US_LINE} from '../helpers/messages'

export default function EmailTelephone () {

  return (
    <div className="email-telephone">
      <h1>{localiseMessage(DROP_US_LINE)}</h1>
      <a href={`tel:${CONTACT_NUMBER.replace(/\(\d+\)|\s/g, '')}`}>{CONTACT_NUMBER}</a>
      <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
    </div>
  )
}
