import React, {useState, useEffect} from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import CookieModal from '../components/cookie-modal'
import VendosModal from '../components/vendos-modal'
import LocaleModal from '../components/locale-modal'
import ContactModal from '../components/contact'
import {useIsScrolled} from '../helpers/hooks'
import {useLocale, shouldShowLocaleModal} from '../helpers/locale'

function Layout ({children, fixHeader, topPadding}) {

  const locale = useLocale()

  const [showVendosModal, setShowVendosModal] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false)
  const [showLocaleModal, setShowLocaleModal] = useState(shouldShowLocaleModal({currentLocale: locale}))
  const isScrolled = useIsScrolled()

  useEffect(() => {

    document.body.classList.toggle('body--no-scroll', showVendosModal || showContactModal || showLocaleModal)
    document.body.classList.toggle('body--shift', showContactModal)

  }, [showVendosModal, showContactModal, showLocaleModal])

  return (
    <main style={{paddingTop: topPadding ? `${topPadding}px` : undefined}}>
      <Header
        onLoginClick={() => setShowVendosModal(true)}
        onContactClick={() => setShowContactModal(true)}
        fix={isScrolled || fixHeader}
      />
      {children}
      <CookieModal />
      <VendosModal
        show={showVendosModal}
        onCloseClick={() => setShowVendosModal(false)}
      />
      <LocaleModal
        show={showLocaleModal}
        onCloseClick={() => setShowLocaleModal(false)}
      />
      <ContactModal
        show={showContactModal}
        onCloseClick={() => setShowContactModal(false)}
      />
      <Footer />
    </main>
  )
}

export default Layout
