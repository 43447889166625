import React, {useState} from 'react'
import classnames from 'classnames'
import {localiseMessage} from '../helpers/locale'
import {SIGNUP_TO_NEWSLETTER, SUBSCRIBED, SUBSCRIBE} from '../helpers/messages'

export default function SubscribeStrap () {

  const [subscribeFormState, setSubscribeFormState] = useState('unsubmitted')
  const artificialDelay = 3000

  const submitForm = formEl => {

    return fetch(formEl.action, {

      method:'post',
      body: new FormData(formEl)

    }).then(() => new Promise(res => setTimeout(res, artificialDelay * .5)))
  }

  const formSubmitted = e => {

    e.preventDefault()

    const formEl = e.target

    setSubscribeFormState('submitting')

    submitForm(formEl).then(() => {

      formEl.reset()

      setSubscribeFormState('submitted')

      setTimeout(() => setSubscribeFormState('unsubmitted'), artificialDelay)

    })
  }

  const subscribeFormSubmitClicked = () => setSubscribeFormState('validating')

  const subscribeFormClasses = classnames({
    'subscribe-strap__subscribe': true,
    [`form--${subscribeFormState}`]: true
  })

  return (

    <div className="subscribe-strap">
      <div className="subscribe-strap__inner">
        <h1>{localiseMessage(SIGNUP_TO_NEWSLETTER)}</h1>
        <form name="subscribe" className={subscribeFormClasses} data-netlify="true" onSubmit={formSubmitted} onInput={() => setSubscribeFormState('unsubmitted')} >
          <input name="email" type="email" placeholder="Email" required />
          <input type="hidden" name="form-name" value="subscribe" />
          <input className="button" type="submit" value={subscribeFormState == 'submitted' ? localiseMessage(SUBSCRIBED) : localiseMessage(SUBSCRIBE)} onClick={subscribeFormSubmitClicked} />
        </form>
      </div>
    </div>
  )
}
