import React from 'react'
import { CONTACT_NUMBER, US_CONTACT_NUMBER } from '../helpers/constants'

export default function Geographies () {

  return (
    <div className="geographies">
      <div className="geographies__item">
        <h2>London</h2>
        <div className="geographies__location">
          <h4>HQ</h4>
          <p>
            Social Vend,<br/>
            1110 Great West Road,<br/>
            TW8 0GP<br/><br/>
            <a href={`tel:${CONTACT_NUMBER.replace(/\(\d+\)|\s/g, '')}`}>{CONTACT_NUMBER}</a>
          </p>
        </div>
        <div className="geographies__location">
          <h4>Satellite</h4>
          <p>
            Social Vend - WeWork,<br/>
            41 Corsham Street,<br/>
            N1 6DR<br/><br/>
          </p>
        </div>
      </div>
      <div className="geographies__item">
        <h2>Denver</h2>
        <div className="geographies__location">
          <h4>North American HQ</h4>
          <p>
            501 Raritan Way,<br/>
            Denver,<br/>
            CO 80204<br/><br/>
            <a href={`tel:${US_CONTACT_NUMBER.replace(/\(\d+\)|\s/g, '')}`}>{US_CONTACT_NUMBER}</a>
          </p>
        </div>
      </div>
    </div>
  )
}
