import React from 'react'
import Helmet from 'react-helmet'
import {unblockPortableTextToPlainText} from '../helpers/sanity'
import {useLocale, localiseObject, getLocaleOfUrl} from '../helpers/locale'
import {SOCIAL_HANDLES, LOCALES} from '../helpers/constants'

export default function SocialMeta ({SEOtitle = {en: 'Social Vend'}, pageSeo, description, url, imageUrl}) {

  const locale = useLocale()

  let titleContent, descriptionContent

  // This will be removed when I roll-out the new pageSeo type accross the board
  if (pageSeo) {

    titleContent = localiseObject(pageSeo.title)
    descriptionContent = localiseObject(pageSeo.description)

  } else {

    const descriptionLocaled = localiseObject(description)

    titleContent = localiseObject(SEOtitle)
    descriptionContent = typeof descriptionLocaled == 'string' ? descriptionLocaled : unblockPortableTextToPlainText(descriptionLocaled)

  }

  return (
    <Helmet>
      <title>{titleContent + ' | Social Vend'}</title>
      <meta name="description" content={descriptionContent}/>
      <meta property="og:type" content="website"/>
      <meta property="og:url" content={url}/>
      <meta property="og:title" content={titleContent}/>
      <meta property="og:image" content={imageUrl}/>
      <meta property="og:description" content={descriptionContent}/>
      <meta property="og:site_name" content="Social Vend"/>
      <meta property="og:locale" content="en_GB"/>
      <meta name="twitter:card" content="summary"/>
      <meta name="twitter:site" content={`@${SOCIAL_HANDLES.TWITTER}`}/>
      <meta name="twitter:creator" content={`@${SOCIAL_HANDLES.TWITTER}`}/>
      <meta name="twitter:url" content={url}/>
      <meta name="twitter:title" content={titleContent}/>
      <meta name="twitter:description" content={descriptionContent}/>
      <meta name="twitter:image" content={imageUrl}/>
      {/* Add other locales for Google crawlers as per https://support.google.com/webmasters/answer/189077?hl=en&ref_topic=2370587 */}
      {LOCALES.map((loc, i) => (
        <link key={i} rel="alternate" hrefLang={loc} href={getLocaleOfUrl({url, fromLocale: locale, toLocale: loc})} />
      ))}
    </Helmet>
  )
}

// Add these when the chance presents itself
// <meta property="og:image:width" content="1200"/>
// <meta property="og:image:height" content="630"/>
